<template>
  <div class="container">
    <img class="cumen" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01871197-3c2a-4f70-3542-2ea5c89bce2d?x-oss-process=style/origin" alt="cumen logo">
    <img class="medal" :src="icon" alt="cumen logo">
    <div class="info">
      <div class="title">{{ title }}</div>
      <div class="other">{{ description }}</div>
      <div class="other">{{ date }}获得</div>
    </div>
    <div class="footer">
      <div class="info">
        <div class="top">
          <img class="avatar" :src="avatar" alt="avatar">
          <img class="gender" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018711b4-d8cb-a470-8337-948ffb8c9e21?x-oss-process=style/origin" alt="avatar">
          <span class="nickname">{{ nickname }}</span>
        </div>
        <div class="bottom">扫码查看Ta的赛事生涯</div>
      </div>
      <img class="qrcode" :src="qrcode" alt="">
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  name: "medal",
  data() {
    return {
      qrcode: ""
    }
  },
  created(){
    const {
      nickname, avatar, userId, icon, title, description, date,
    } = this.$route.query;
    this.nickname = nickname;
    this.avatar = avatar;
    this.userId = userId;
    this.icon = icon;
    this.title = title;
    this.description = description;
    this.date = date;
    this.EncodeCumenId(userId);
  },
  methods: {
    EncodeCumenId(id) {
      $fetch
        .post("EncodeCumenId", { id })
        .then((res) => {
          this.getWechatCode(res.id);
        });
    },
    getWechatCode(scene) {
      const _ = this;
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/leagues/pages/career/index',
          scene,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.122;

.container {
  position: relative;
  width: 100vw;
  height: 145.2vw;
  background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01871197-3c28-5336-fd01-17ca2921e030?x-oss-process=style/origin") no-repeat center center / cover;
  border-radius: px2rem(32 * $multipleCount);
  overflow: hidden;

  > .cumen {
    position: absolute;
    top: px2rem(40 * $multipleCount);
    left: px2rem(52 * $multipleCount);
    width: px2rem(153.77 * $multipleCount);
    height: px2rem(32 * $multipleCount);
  }

  > .medal {
    position: absolute;
    top: px2rem(72.82 * $multipleCount);
    left: px2rem(78.82 * $multipleCount);
    width: px2rem(516.36 * $multipleCount);
    height: px2rem(516.36 * $multipleCount);
    object-fit: cover;
  }

  > .info {
    position: absolute;
    left: px2rem(72 * $multipleCount);
    top: px2rem(568 * $multipleCount);
    width: calc(100% - px2rem(72 * 2 * $multipleCount));

    .title {
      // @include ellipsis;
      font-size: px2rem(36 * $multipleCount);
      font-weight: 1000;
      text-align: center;
      color: #222;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
    }

    .other {
      font-family: Alibaba PuHuiTi 2.0;
      font-size: px2rem(24 * $multipleCount);
      text-align: center;
      color: rgba(34, 34, 34, .4);
      margin-top: px2rem(16 * $multipleCount);
    }
  }

  > .footer {
    @include row(space-between, center);
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - px2rem((58 + 48) * $multipleCount));
    height: px2rem(176 * $multipleCount);
    margin-left: px2rem(58 * $multipleCount);
    margin-right: px2rem(48 * $multipleCount);

    > .info {

      > .top {
        @include row;
        position: relative;

        > .avatar {
          position: relative;
          width: px2rem(48 * $multipleCount);
          height: px2rem(48 * $multipleCount);
          border-radius: 50%;
          border: 1px solid #ff82B9;
        }

        > .gender {
          content: "";
          position: absolute;
          left: px2rem(38 * $multipleCount);
          bottom: 0;
          width: px2rem(12 * $multipleCount);
          height: px2rem(12 * $multipleCount);
          background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018711b4-d8cb-a470-8337-948ffb8c9e21?x-oss-process=style/origin") no-repeat center center / cover;
        }

        > .nickname {
          color: #222;
          font-size: px2rem(28 * $multipleCount);
          font-weight: 900;
          line-height: px2rem(48 * $multipleCount);
          margin-left: px2rem(8 * $multipleCount);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        }
      }

      > .bottom {
        font-size: px2rem(24 * $multipleCount);
        color: rgba(34, 34, 34, .3);
        margin-top: px2rem(14 *  $multipleCount);
      }
    }

    > .qrcode {
      width: px2rem(112 * $multipleCount);
      height: px2rem(112 * $multipleCount);
      object-fit: contain;
    }
  }
}
</style>
